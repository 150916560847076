body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

code {
  font-family: Arial, sans-serif;
  font-optical-sizing: auto;
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}