.table-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  h2 {
    text-align: center;
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .data-table th {
    padding: 12px;
    background-color: #4CAF50;
    color: #fff;
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: bold;
  }
  .data-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
    color: #555;
    font-size: 0.95rem;
  }
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  .data-table tr:nth-child(odd) {
    background-color: #fff;
  }
  .data-table tr:hover {
    background-color: #e8f5e9;
    cursor: pointer;
  }
  .data-table td[colspan="5"] {
    text-align: center;
    font-style: italic;
    color: #999;
    padding: 15px;
  }
  @media (max-width: 768px) {
    .table-container {
      width: 100%;
      padding: 10px;
    }
    .data-table th, .data-table td {
      padding: 8px;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
  